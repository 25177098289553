import {
  Box,
  Button,
  Card,
  Container,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import "./Notes.scss";
import { DataGrid } from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState, useContext, useEffect } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { languages } from "../../App";
import axios from "axios";
import MainBackBtn from "../../_Components/Common/BackBtn/MainBackBtn";
import {
  PrimaryBgBtn,
  PrimaryOutlineBtn,
} from "../../_Components/Common/Buttons/CustomBtns";
import CustomDialog from "../../_Components/Common/CustomDialog/CustomDialog";
import MainCard from "../../_Components/Common/Cards/MainCard";

const MyNotes = () => {
  let curLanguage = useContext(languages);
  const nToken = localStorage.getItem("nToken");
  const userInfoData = localStorage.getItem("userInfo");
  const userinfoObject = JSON.parse(userInfoData);
  const [rows, setRows] = useState([]);
  const [dltPopUp, setDltPopUp] = useState(false);
  const [eventData, setEventData] = useState({});
  const [viewOpen, setViewOpen] = useState(false);
  const [addNoteState, setAddNoteState] = useState(false);
  const [addNotes, setAddNotes] = useState({
    title: "",
    text: "",
  });
  const [state, setState] = useState({
    isEditing: true,
    currentID: null,
    actualID: null,
  });
  const [btnSaveClick, SetbtnSaveClick] = useState(false);
  const viewHandleClose = () => {
    setViewOpen(false);
    setAddNoteState(false);
    setEventData({});
    setState((prev) => ({
      ...prev,
      isEditing: true,
    }));
    setAddNotes("");
  };

  const handleChange = (e) => {
    if (!state.isEditing && addNoteState) {
      if (e.target.parentNode.parentNode.getAttribute("usedFor") === "title") {
        setAddNotes((prev) => ({
          ...prev,
          title: e.target.value,
        }));
      } else {
        setAddNotes((prev) => ({
          ...prev,
          text: e.target.value,
        }));
      }
      return;
    }

    if (e.target.parentNode.parentNode.getAttribute("usedFor") === "title") {
      rows[e.target.id].title = e.target.value;
    } else {
      rows[e.target.id].text = e.target.value;
    }

    setState((prev) => ({
      ...prev,
      rows,
    }));
  };

  const editHandler = async (e) => {
    console.log("rajji");

    // SetbtnSaveClick(true)
    if (!state.isEditing && addNoteState) {
      SetbtnSaveClick(true);
      if (!addNotes.title || !addNotes.text) {
        SetbtnSaveClick(false);
        alert("Enter both the title and description to proceed with saving");
        return;
      }

      await axios.post(
        `${process.env.REACT_APP_nodewsPrefix}/createNote`,
        {
          userid: userinfoObject[0].id,
          modname: "app",
          contextid: 0,
          title: addNotes.title,
          text: addNotes.text,
          otherinfo: "",
        },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      );

      setAddNoteState(false);
      setViewOpen(false);
      setAddNotes("");
      SetbtnSaveClick();
      return;
    }

    if (!state.isEditing) {
      SetbtnSaveClick(true);
      if (!rows[state.currentID]?.title || !rows[state.currentID]?.text) {
        alert("Enter both the title and description to proceed with saving");
        return;
      }

      await axios.post(
        `${process.env.REACT_APP_nodewsPrefix}/updateNote`,
        {
          id: e.currentTarget.id,
          userid: userinfoObject[0].id,
          title: rows[state.currentID]?.title,
          text: rows[state.currentID]?.text,
        },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      );

      setState((prev) => ({
        ...prev,
        isEditing: true,
      }));
      setAddNoteState(false);
      setViewOpen(false);
      setEventData("");
      setAddNotes("");
      SetbtnSaveClick();
    }

    setState((prev) => ({
      ...prev,
      isEditing: false,
    }));
  };

  const onViewClicked = (e, cellValues) => {
    setState((prev) => ({
      ...prev,
      isEditing: true,
      currentID: cellValues.row.tempID,
    }));
    setEventData(cellValues);
    setViewOpen(true);
  };

  const onDeleteClicked = (id, tempID) => {
    console.log("delete note ", id, tempID);
    setDltPopUp(true);
    setState((prev) => ({
      ...prev,
      currentID: tempID,
      actualID: id,
    }));
  };

  const deleteNoteHandler = () => {
    axios
      .post(
        `${process.env.REACT_APP_nodewsPrefix}/deleteNote`,
        {
          id: state.actualID,
        },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      )
      .then((response) => {
        setDltPopUp(false);
        let arr = [...rows];
        arr.splice(state.currentID, 1);
        setState(arr);
      });
  };

  const addNoteHandler = () => {
    SetbtnSaveClick(false);
    setAddNoteState(true);
    setViewOpen(true);
    setState((prev) => ({
      ...prev,
      isEditing: false,
    }));
  };

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_nodewsPrefix}/getAllnotesByid`,
        {
          userid: userinfoObject[0].id,
        },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      )
      .then((response) => {
        setRows(response.data.note);
      });
  }, [dltPopUp, viewOpen]);

  const columns = [
    {
      field: "Status",
      headerName: curLanguage.status,
      hideable: false,
      width: 100,
      renderCell: (cellValues) => {
        return (
          <Button
            id={cellValues.row.tempID}
            variant="contained"
            sx={{ backgroundColor: "iconBg.main", color: "iconColor.color" }}
            onClick={(e) => {
              onViewClicked(e, cellValues);
            }}
            className="reviewButton"
          >
            <Typography sx={{ color: "iconColor.color" }}>View</Typography>
          </Button>
        );
      },
    },
    {
      field: "title",
      headerName: curLanguage.title,
      type: "string",
      width: 200,
    },
    {
      field: "text",
      headerName: curLanguage.description,
      type: "string",
      width: 400,
    },
    {
      field: "timecreated",
      headerName: curLanguage.createdOn,
      type: "singleSelect",
      width: 180,
    },
    {
      field: "lastedited",
      headerName: curLanguage.modifiedOn,
      type: "singleSelect",
      width: 180,
    },
    {
      field: "Actions",
      headerName: curLanguage.action,
      type: "actions",
      width: 120,
      cellClassName: "customCell",
      getActions: (params) => [
        <Button
          variant="contained"
          sx={{ backgroundColor: "iconBg.main", color: "iconBg.contrast" }}
          className="circleBtn"
          key={params.id}
          onClick={() => onDeleteClicked(params.id, params.row.tempID)}
        >
          <RiDeleteBin6Line />
        </Button>,
      ],
    },
  ];

  return (
    <>
      <CustomDialog
        dialogTitle={"Delete note?"}
        isOpen={dltPopUp}
        onClose={() => setDltPopUp(!dltPopUp)}
        actionBtn={
          <>
            <PrimaryBgBtn onClick={deleteNoteHandler}>Yes</PrimaryBgBtn>
            <PrimaryOutlineBtn onClick={() => setDltPopUp(false)}>
              No
            </PrimaryOutlineBtn>
          </>
        }
      >
        <Box px={2} pb={2}>
          Are you sure you want to delete this note? This action cannot be
          undone.
        </Box>
      </CustomDialog>

      <CustomDialog
        dialogTitle={
          <Box className={`title ${"noteHead"}`}>
            <TextField
              required
              label={state.isEditing ? "" : "Enter Title"}
              variant="outlined"
              fullWidth
              disabled={state.isEditing}
              usedFor="title"
              id={eventData.row?.tempID}
              value={eventData.row && rows[eventData.row.tempID]?.title}
              onChange={handleChange}
              placeholder="Enter Title"
            />
          </Box>
        }
        isOpen={viewOpen}
        onClose={viewHandleClose}
        className="notesDialogBox"
        actionBtn={
          <>
            <PrimaryBgBtn
              id={eventData.row?.id}
              disabled={btnSaveClick}
              onClick={editHandler}
            >
              {!state.isEditing ? "Save" : "Edit"}
            </PrimaryBgBtn>
            <PrimaryOutlineBtn onClick={viewHandleClose}>
              Cancel
            </PrimaryOutlineBtn>
          </>
        }
      >
        <Box px={2} pb={2} className="noteContent">
          <TextField
            required
            label={state.isEditing ? "" : "Enter Description"}
            variant="outlined"
            fullWidth
            multiline
            rows={15}
            disabled={state.isEditing}
            usedFor="text"
            placeholder="Enter Description"
            id={eventData.row?.tempID}
            value={eventData.row && rows[eventData.row.tempID].text}
            onChange={handleChange}
          />

          <Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
            <InputLabel id="Created" className="modalLabel">
              <Typography>
                {eventData.row && rows[eventData.row.tempID].timecreated
                  ? "Created On:"
                  : ""}{" "}
                &nbsp;
                <b>{eventData.row && rows[eventData.row.tempID].timecreated}</b>
              </Typography>
            </InputLabel>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
            <InputLabel id="Created" className="modalLabel">
              <Typography>
                {eventData.row && rows[eventData.row.tempID].lastedited
                  ? "Last Modified:"
                  : ""}
                &nbsp;
                <b>{eventData.row && rows[eventData.row.tempID].lastedited}</b>
              </Typography>
            </InputLabel>
          </Box>
        </Box>
      </CustomDialog>

      <MainCard
        cardTitle={curLanguage.mynotes}
        additionalData={
          <PrimaryBgBtn variant="contained" onClick={addNoteHandler}>
            Add notes
          </PrimaryBgBtn>
        }
      >
        <DataGrid
          columns={columns}
          rows={rows}
          autoHeight={false}
          /* checkboxSelection */
          componentsProps={{
            columnsPanel: { disableHideAllButton: true },
          }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          classes={{
            virtualScroller: "customVirtualScroller",
          }}
          sx={{
            border: "none",
            boxShadow: "none",
          }}
        />
      </MainCard>
    </>
  );
};

export default MyNotes;

import axios from "axios";
import dayjs from "dayjs";
import React, { useState, useContext, useEffect } from "react";
import {
  Alert,
  Box,
  Checkbox,
  DialogActions,
  FormControl,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import "./createCalendarEvent.scss";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { languages } from "../../../App";
import ProcessText from "../../../_Components/Common/ProceessText";
import {
  PrimaryBgBtn,
  PrimaryOutlineBtn,
} from "../../../_Components/Common/Buttons/CustomBtns";
import { useSelector } from "react-redux";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";

const NewCalendarEvent = ({ dates, months, years, close, onEventUpdate }) => {
  const [loading, setLoading] = useState(false);
  let curLanguage = useContext(languages);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const courseDetails = JSON.parse(localStorage.getItem("courseDetails"));
  const token = localStorage.getItem("token");
  const nToken = localStorage.getItem("nToken");
  const date = dates;
  const [showGrids, setShowGrids] = useState(false);
  const [eventInfo, setEventInfo] = useState({
    title: "",
    description: "",
    subject: 0,
    section: 0,
    eventtype: "user",
    repeatCount: 0,
    date: "",
    courseid: 0,
    classsection: 0,
  });
  const [error, setError] = useState("");
  const [classSec, setClassSec] = useState([]);
  const [checked, setChecked] = useState({
    repeat: false,
    duration: "Withoutduration",
  });
  const fullDate = `${years}-${months + 1}-${date}`;
  const [eventDate, setEventDate] = useState({
    date: dayjs(fullDate).date(),
    month: dayjs(fullDate).month() + 1,
    year: dayjs(fullDate).year(),
  });

  const [eventTime, setEventTime] = useState({
    hour: 0,
    minute: 0,
  });
  const minDateTime = dayjs();
  const maxDateTime = dayjs().endOf("year");
  const handleDateChange = (e) => {
    setEventDate({
      date: e.$D,
      month: e.$M + 1,
      year: e.$y,
      hour: e.$H,
      minute: e.$m,
    });
  };
  const handleTimeChange = (e) => {
    setEventTime({
      hour: e.$H,
      minute: e.$m,
    });
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setEventInfo({ ...eventInfo, [name]: value });
    setError("");
    setLoading(false);
  };

  const handleEventChange = (event) => {
    setEventInfo({ ...eventInfo, eventtype: event.target.value });
    if (event.target.value === "user") {
      setShowGrids(false);
    } else {
      setShowGrids(true);
    }
  };

  const handleChangecheck = (e) => {
    if (e.target.value === "repeat") {
      setChecked({ repeat: e.target.checked, duration: e.target.value });
    } else {
      setChecked({ repeat: false, duration: e.target.value });
    }
  };
  const filterSubjects = (selectedClass) => {
    const filteredCourses = courseDetails.filter(
      (data) => ProcessText(data.shortname).number === Number(selectedClass)
    );
    return filteredCourses;
  };

  const submit = async (event, required) => {
    event.preventDefault();
    setLoading(true);

    if (eventInfo.title.trim() === "") {
      setError(true);
      return;
    }
    
   
    const createEventURL = `${process.env.REACT_APP_nodewsPrefix}/createCalenderEvent`;
    try {
      const response = await axios.post(
        createEventURL,
        {
          title: eventInfo.title,
          description: eventInfo.description,
          repeats: eventInfo.repeatCount,
          courseid: eventInfo.subject,
          stdClass: eventInfo.classsection,
          section: eventInfo.section,
          eventtype: eventInfo.eventtype,
          year: eventDate.year,
          month: eventDate.month,
          date: eventDate.date,
          hour: eventTime.hour,
          minute: eventTime.minute,
          token: token,
          instituteid: userinfo[0]?.institution,
          url: `${process.env.REACT_APP_wsPrefix}`,
        },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      );
      setEventInfo(response.data);
      onEventUpdate(response.data, dayjs(fullDate).date(), eventDate.date);
      const { title } = eventInfo;
      alert(title + " Created");
      close(false);
    } catch (error) {
      console.error("Error while creating event:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseURL = `${process.env.REACT_APP_nodewsPrefix}/getTeacherAssignedClassStudent?teacher_id=${userinfo[0]?.id}&schoolcode=${userinfo[0]?.institution}`;
        const response = await axios.get(baseURL, {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        });
        setClassSec(response.data.rows);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const classData = useSelector((state) => state.items);
  const data1 =
    classData.classSecData.length > 0 ? classData.classSecData : classSec;
  function getSectionsByClass(selectedClass) {
    return classSec
      .filter((item) => item.class === selectedClass)
      .map((item) => item.section);
  }
  const sections = getSectionsByClass(eventInfo.classsection).sort();
  return (
    <Box>
      <form onSubmit={submit} autoComplete="off">
        {error && (
          <Box className="errorBox">
            <Alert severity="error" className="errorContent">
              {error}
            </Alert>
          </Box>
        )}
        <Grid container spacing={2} className="eventcontent">
          <Grid item md={5}>
            <TextField
              required
              id="outlined-basic"
              label="Title"
              variant="outlined"
              name="title"
              
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={4} className="customDate">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={dayjs(fullDate)}
                label=""
                onChange={handleDateChange}
                name="date"
                disablePast
              />
            </LocalizationProvider>
          </Grid>
          <Grid item md={3} className="customDate">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                value={eventTime}
                onChange={handleTimeChange}
                name="date"
                minDateTime={minDateTime}
                maxDateTime={maxDateTime}
              />
            </LocalizationProvider>
          </Grid>
          {userinfo[0]?.department.toLowerCase() === "student" ? (
            ""
          ) : (
            <Grid item md={12}>
              <Grid container alignItems={"center"}>
                <Grid item md={4}>
                  <FormLabel component="legend">Create event for</FormLabel>
                </Grid>
                <Grid item md={8}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="eventtype"
                      name="eventtype"
                      value={eventInfo.eventtype}
                      onChange={handleEventChange}
                      defaultValue="Classes"
                      className="eventBtnsSec"
                    >
                      <Grid container>
                        <Grid item md={6}>
                          <FormControlLabel
                            value="user"
                            control={<Radio />}
                            label="Self"
                          />
                        </Grid>
                        {userinfo[0]?.department.toLowerCase() ===
                          "pedagogy" && (
                          <Grid item md={6}>
                            <FormControlLabel
                              value="course"
                              control={<Radio />}
                              label="Class"
                            />
                          </Grid>
                        )}
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* Class and Sections start here */}
          {showGrids && (
            <>
              <Grid item md={4}>
                <FormControl>
                  <InputLabel id="demo-simple-select-helper-label">
                    Class
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={eventInfo.classsection}
                    name="classsection"
                    label="class"
                    onChange={handleChange}
                  >
                    {Array.from(
                      new Set(
                        data1?.map((obj) => obj.class).sort((a, b) => a - b)
                      )
                    ).map((data) => {
                      return (
                        <MenuItem key={data} value={data}>
                          Class {data}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl>
                  <InputLabel id="demo-simple-select-helper-label">
                    section
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={eventInfo.section}
                    name="section"
                    label="section"
                    onChange={handleChange}
                    sx={{ textTransform: "capitalize" }}
                    error={error}
                    
                  >
                    {Array.from(new Set(sections)).map((value) => (
                      <MenuItem
                        key={value}
                        value={value}
                        sx={{ textTransform: "capitalize" }}
                      >
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl>
                  <InputLabel id="demo-simple-select-helper-label">
                    Subject
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={eventInfo.subject}
                    name="subject"
                    label="Subject"
                    onChange={handleChange}
                    error={error}
                  >
                    {filterSubjects(eventInfo.classsection).map((data) => (
                      <MenuItem key={data.id} value={data.id}>
                        {data.fullname}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}

          {/* Class and Sections end here */}
          <Grid item md={12}>
            <TextField
              id="outlined-multiline-flexible"
              label={curLanguage.description}
              multiline
              Rows={10}
              name="description"
              onChange={handleChange}
              className="customTxt"
            />
          </Grid>

          {/* until duration Section start here */}
          {/* 
          <Grid item md={12}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="Withoutduration"
              name="radio-buttons-group"
            >
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Grid container spacing={2}>
                    <Grid item md={8}>
                      <FormControlLabel
                        value="Withoutduration"
                        control={<Radio />}
                        label="Without duration"
                        onChange={handleChangecheck}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <FormControlLabel
                        value="until"
                        control={<Radio />}
                        onChange={handleChangecheck}
                        label="Until"
                      />
                    </Grid>
                  </Grid>
                  <Box className="customDate">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DateTimePicker"]}>
                        <DateTimePicker
                          name="calUntil"
                          disabled={checked.duration !== "until"}
                          value={dayjs(fullDate)}
                          label={curLanguage.eventDateUntil}
                          sx={{ mr: 0 }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box className="radioButtonDuration">
                    <FormControlLabel
                      value="minutes"
                      control={<Radio />}
                      label="Duration in minutes"
                      onChange={handleChangecheck}
                    />
                  </Box>

                  <Box className="minutesDuration inputbox">
                    <TextField
                      id="outlined-multiline-flexible"
                      label={curLanguage.minutes}
                      multiline
                      maxRows={1}
                      name="Minutes"
                      disabled={checked.duration !== "minutes"}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>
              </Grid>
            </RadioGroup>
          </Grid> */}
          {/* until duration Section end here */}

          <Grid item md={12}>
            <Grid container>
              <Grid item md={4}>
                <FormControlLabel
                  control={
                    <Checkbox value="repeat" onChange={handleChangecheck} />
                  }
                  label={curLanguage.repeatThisEvent}
                />
              </Grid>
              <Grid item md={3}>
                <Box className="repeatEventCount  inputbox">
                  <TextField
                    id="outlined-multiline-flexible"
                    label={curLanguage.repeatWeeklyEvent}
                    multiline
                    maxRows={1}
                    name="repeatCount"
                    value={eventInfo.repeatCount}
                    disabled={!checked.repeat}
                    onChange={handleChange}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <DialogActions
          sx={{
            borderColor: "secondaryColorOutline.border",
            backgroundColor: "cardColor.main",
          }}
        >
          <PrimaryOutlineBtn onClick={() => close(false)}>
            {curLanguage.cancel}
          </PrimaryOutlineBtn>
          <PrimaryBgBtn type="submit" disabled={loading ? true : false}>
            {curLanguage.save}
          </PrimaryBgBtn>
        </DialogActions>
      </form>
    </Box>
  );
};
export default NewCalendarEvent;

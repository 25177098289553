import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { languages } from "../../App";
import axios from "axios";
import FeeInfo from "./FeeInfo";
import BackButton from "../../_Components/Common/BackBtn/BackBtn";
import MainCard from "../../_Components/Common/Cards/MainCard";

function FeeCollection() {
  const nToken = localStorage.getItem("nToken");
  let curLanguage = useContext(languages);
  const [feeData, setFeeData] = useState([]);
  const [feeStatus, setFeeStatus] = useState();
  const [searchCriteria, setSearchCriteria] = useState({
    admission_no: "",
    student_name: "",
    class: "",
    section: "",
    father_name: "",
  });

  const handleInput = (event) => {
    const { name, value, type } = event.target;
    if (type === "text") {
      if (/^[A-Za-z ]+$/.test(value) || value === "") {
        setSearchCriteria((prevCriteria) => ({
          ...prevCriteria,
          [name]: value,
        }));
      }
    } else {
      setSearchCriteria((prevCriteria) => ({
        ...prevCriteria,
        [name]: value,
      }));
    }
  };

  const feeInfo = (event) => {
    event.preventDefault();
    const feesInfo = `${process.env.REACT_APP_nodewsPrefix}/getFeeData`;
    axios
      .post(feesInfo, searchCriteria, {
        headers: {
          Authorization: `Bearer ${nToken}`,
        },
      })
      .then((response) => {
        setFeeData(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    setFeeStatus(feeData);
  };
  const isEmptySearchCriteria = () => {
    return Object.values(searchCriteria).every((value) => value === "");
  };
  return (
    <MainCard cardTitle="Fees">
      <Box mt={2} component="form" onSubmit={feeInfo} noValidate>
        <Grid container sx={{ px: 4, mb: 4 }} spacing={2}>
          <Grid item md={3}>
            <TextField
              name="admission_no"
              type="number"
              id="outlined-required"
              label={curLanguage.admissionno}
              sx={{ width: "100%" }}
              onChange={handleInput}
              value={searchCriteria.admission_no}
            />
          </Grid>
          <Grid item md={1.5} xs={12}>
            <FormControl sx={{ width: "100%", m: 0 }}>
              <InputLabel id="demo-select-small-label">
                {curLanguage.class}
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                name="class"
                label="class"
                onChange={handleInput}
              >
                <MenuItem value="LKG">LKG</MenuItem>
                <MenuItem value="UKG">UKG</MenuItem>
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
                <MenuItem value="5">5</MenuItem>
                <MenuItem value="6">6</MenuItem>
                <MenuItem value="7">7</MenuItem>
                <MenuItem value="8">8</MenuItem>
                <MenuItem value="9">9</MenuItem>
                <MenuItem value="10">10</MenuItem>
                <MenuItem value="11">11</MenuItem>
                <MenuItem value="12">12</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={1.5} xs={12}>
            <FormControl sx={{ width: "100%", m: 0 }}>
              <InputLabel id="demo-select-small-label">
                {curLanguage.section}
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                name="section"
                label="section"
                onChange={handleInput}
              >
                <MenuItem value="A">A</MenuItem>
                <MenuItem value="B">B</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={3}>
            <TextField
              name="student_name"
              id="outlined-required"
              label={curLanguage.studentname}
              sx={{ width: "100%" }}
              onChange={handleInput}
              value={searchCriteria.student_name}
              type="text"
            />
          </Grid>

          <Grid item md={3}>
            <TextField
              name="father_name"
              id="outlined-required"
              label={curLanguage.fathername}
              sx={{ width: "100%" }}
              onChange={handleInput}
              value={searchCriteria.father_name}
              type="text"
            />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="secondaryColor"
          className="btn"
          sx={{ margin: "10px auto", display: "flex", bottom: 10 }}
          type="submit"
          disabled={isEmptySearchCriteria()}
        >
          {curLanguage.search}
        </Button>
      </Box>
      {feeStatus && (
        <Card
          sx={{
            mb: 4,
            backgroundColor: "cardColor.main",
            color: "cardColor.contrast",
          }}
          elevation={3}
          className="customTbl"
        >
          <Typography variant="h4">
            <b>
              {curLanguage.fees} {curLanguage.status}
            </b>
          </Typography>
          <FeeInfo data={feeData} />
        </Card>
      )}
    </MainCard>
  );
}
export default FeeCollection;

// src/FormComponent.jsx
import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, Typography, Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import axios from 'axios';
import MainCard from '../Cards/MainCard';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { PrimaryBgBtn } from '../Buttons/CustomBtns';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { NavLink } from 'react-router-dom';
const CreateTableForm = () => {
    const [open, setOpen] = useState(false);
    const [ProceedPopup, setProceedPopup] = useState(false);
    const [formData, setFormData] = useState({
        formcode: '',
        formcomment: '',
        entries: '',
    });
    const [entry, setEntry] = useState([
        {
            columnName: '',
            columncomment: '',
            dataType: '',
            length: '',
            optional: ''
        }
    ]);
    const [showCustomField, setShowCustomField] = useState(false);
    const [data, setData] = useState([]);
    const nToken = localStorage.getItem("nToken");
    const getuserInfo = localStorage.getItem("userInfo")
    const getfinancialYear = localStorage.getItem("AY")
    var financialYear = JSON.parse(getfinancialYear)
    const userInfo = JSON.parse(getuserInfo)
    var schoolcode = userInfo[0].institution
    console.log(schoolcode, financialYear);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseProceedPopup = () => {
        setProceedPopup(false);
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleEntryChange = (index, event) => {
        const { name, value } = event.target;
        const columnData = [...entry];
        columnData[index][name] = value;
        setEntry(columnData);
    };
    const addEntry = () => {
        console.log(entry, "entry data");
        setFormData({
            ...formData,
            entries: [...formData.entries, ...entry],
        });
        setEntry([
            ...entry,
            {
                columnName: '',
                columncomment: '',
                dataType: '',
                length: '',
                optional: ''
            },
        ]);
    };
    const removeRow = (index) => {
        const newFormValues = entry.filter((_, i) => i !== index);
        setEntry(newFormValues);
    };
    const handleSubmit = (e) => {
        setOpen(false);
        setProceedPopup(true);
        setFormData({
            ...formData,
            entries: [...formData.entries, ...entry],
        });
        e.preventDefault();
        const getColumnNameArray = entry.map(item => {
            return item.columnName.replace(/ /g, "_");
        })
        console.log(getColumnNameArray);
        setFormData({ ...formData, formcode: '', formcomment: '' })
        submit()
        async function submit() {
            const url = `${process.env.REACT_APP_nodewsPrefix}/createTableDynamic`;
            let res = await axios.post(url, {
                title: formData.formcode.replace(/ /g, "_"),
                schoolcode: schoolcode,
                description: formData.formcomment,
                customColumn: IDS,
                columnName: getColumnNameArray,
            },
                {
                    headers: {
                        Authorization: `Bearer ${nToken}`
                    }
                }
            )
            setStudent([])
            setTeacher([])
            setEntry([])
            console.log(res);
        }
    };
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    useEffect(() => {
        const url = `${process.env.REACT_APP_nodewsPrefix}/viewColumnMapping?schoolcode=${schoolcode}&academic_year=${financialYear}`
        axios.get(url, {
            headers: {
                Authorization: `Bearer ${nToken}`
            }
        }).then((response) => {
            setData(response.data.column_data)
        });
    }, [])
    console.log(data);

    const StudentDetails = data.filter(item => {
        return item.section === "student"
    }).map(value => {
        return value.section_data
    });
    const TeacherDetails = data.filter(item => {
        return item.section === "teacher"
    }).map(value => {
        return value.section_data
    });

    const [student, setStudent] = useState([]);
    const [teacher, setTeacher] = useState([]);
    const handleChangeStd = (event) => {
        const {
            target: { value },
        } = event;
        setStudent(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleChangeTech = (event) => {
        const {
            target: { value },
        } = event;
        setTeacher(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    console.log(student, teacher);
    const findIdss = (data, student) => {
        return data
            .filter(item => student.includes(item.section_data))
            .map(item => item.id);
    };

    const matchingIds = findIdss(data, student);
    console.log(matchingIds);
    const findIdt = (data, teacher) => {
        return data
            .filter(item => teacher.includes(item.section_data))
            .map(item => item.id);
    };

    const matchingIdt = findIdt(data, teacher);
    console.log(matchingIdt);
    const IDS = matchingIds.concat(matchingIdt)
    console.log(IDS);

    const handleCustomEntryClick = () => {
        setShowCustomField(true);
    };
    return (
        <MainCard cardTitle="Report Card" customClass="">
            <Typography variant="h6">Create Module Name</Typography>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        label="Title"
                        name="formcode"
                        value={formData.formcode}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required='please enter formcode'
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Description"
                        name="formcomment"
                        value={formData.formcomment}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                </Grid>
            </Grid>
            <Box sx={{ border: '1px solid #ccc', padding: 2, borderRadius: 2, marginTop: 2 }}>
                <Typography variant="subtitle1">Add Column Entry</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="demo-multiple-checkbox-label">Student Data</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={student}
                                onChange={handleChangeStd}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                                {StudentDetails.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        <Checkbox checked={student.indexOf(name) > -1} />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="demo-multiple-checkbox-label">Teacher Data</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={teacher}
                                onChange={handleChangeTech}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                                {TeacherDetails.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        <Checkbox checked={teacher.indexOf(name) > -1} />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <PrimaryBgBtn sx={{ m: 1 }} onClick={handleCustomEntryClick}>Custom Entry</PrimaryBgBtn>
                    </Grid>
                </Grid>
                {showCustomField && (
                    entry.map((entryData, index) => (
                        <Grid container spacing={1} mt={1} key={index}>
                            <Grid item md={2}>
                                <TextField
                                    label="Field Name"
                                    name="columnName"
                                    value={entryData.columnName}
                                    onChange={(event) => handleEntryChange(index, event)}
                                    fullWidth
                                    margin="normal"
                                    inputProps={{ maxLength: 20 }}
                                    required
                                />
                            </Grid>

                            {entry.length > 1 && (
                                <IconButton aria-label="Delete row" sx={{ padding: "2px" }}>
                                    <AiOutlineMinusCircle onClick={() => removeRow(index)} fontSize={26} />
                                </IconButton>
                            )}

                            <IconButton aria-label="Add row for other class or subject" sx={{ padding: "1px" }}>
                                <AiOutlinePlusCircle onClick={addEntry} fontSize={26} />
                            </IconButton>
                        </Grid>
                    ))

                )}

            </Box>
            <PrimaryBgBtn onClick={handleClickOpen} sx={{ marginTop: 2 }}>
                Submit
            </PrimaryBgBtn>
            <NavLink to="/viewModules" style={{ marginLeft: 4}} className="btn primaryBtn">View All Modules</NavLink>
            {/* <PrimaryBgBtn sx={{ marginTop: 2, marginLeft: 4 }}>
                View All Modules
            </PrimaryBgBtn> */}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Warning:"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are about to save data, please ensure all information is correct; this action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <PrimaryBgBtn onClick={handleSubmit}>Submit</PrimaryBgBtn>
                    <PrimaryBgBtn onClick={handleClose} >
                        cancel
                    </PrimaryBgBtn>
                </DialogActions>
            </Dialog>
            <Dialog
                open={ProceedPopup}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Report Card Column"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Table Created Successfully.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <PrimaryBgBtn onClick={handleCloseProceedPopup} >
                        OK
                    </PrimaryBgBtn>
                </DialogActions>
            </Dialog>
        </MainCard>
    );
};
export default CreateTableForm;




import axios from "axios";
import React, { useEffect, useState } from "react";
import { Box, Button, Card, Container, Grid, Typography } from "@mui/material";
import BackButton from "../../_Components/Common/BackBtn/BackBtn";
import { DataGrid } from "@mui/x-data-grid";
import { useLocation } from "react-router-dom";
import "./Assignment.scss";

function Assignment() {
  const locationData = useLocation();
  const assignmentID = parseInt(locationData.state.assignmentId);
  const assignmentData = locationData.state.viewAssessment || [];
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const nToken = localStorage.getItem("nToken");
  const mToken = localStorage.getItem("mToken");
  const [rows, setRows] = useState([]);

  const columnsTeacher = [
    {
      field: "Status",
      headerName: "Action",
      width: 100,
      renderCell: renderButton,
    },
    { field: "studentName", headerName: "Student Name", width: 200 },
    { field: "submitedOn", headerName: "Submitted On", width: 180 },
    { field: "marks", headerName: "Marks" },
    { field: "gradePerc", headerName: "Grade %" },
  ];

  const columns = userInfo[0]?.department.toLowerCase() === "pedagogy" ? columnsTeacher : [];

  useEffect(() => {
    if (assignmentData.length > 0) {
      const filteredData = assignmentData.filter(
        (data) => data.assignmentuniqueid === assignmentID
      );
      console.log(filteredData[0].student, "filteredData")
      const newRows = filteredData[0].student.map((values, indx) => ({
        id: indx,
        studentName: `${values.firstname} ${values.lastname}`,
        submitedOn: values.timecreated,
        marks: values.maxmarks,
        gradePerc: values.marks,
        scoid: values.scoid,
        classid: values.classid,
      }));
      setRows(newRows);
    }
  }, [assignmentData, assignmentID]);

  const openLinkInTab = (scoid, stdId) => {
    axios
      .get(`${process.env.REACT_APP_nodewsPrefix}/getScoid?scormid=${scoid}`)
      .then((response) => {
        const link = `${process.env.REACT_APP_wsPrefix}/mod/scorm/scormplayer.php?a=${scoid}&currentorg=ratnasagar&scoid=${response.data.id}&sesskey=hP1eN08thy&display=popup&mode=normal&b-token=${nToken}&token=${mToken}&cid=${stdId}`;
        window.open(link, "");
      });
  };

  function renderButton(cellValues) {
    return (
      <Button
        id={cellValues.row.scoid}
        variant="contained"
        sx={{
          backgroundColor: "secondaryColor.main",
          color: "secondaryColor.contrastText",
        }}
        onClick={() => openLinkInTab(cellValues.row.scoid, cellValues.row.classid)}
      >
        <Typography>Go</Typography>
      </Button>
    );
  }

  return (
    <Container maxWidth="xl">
      <Card
        className="customCard"
        sx={{
          mb: 4,
          backgroundColor: "cardColor.main",
          color: "cardColor.contrast",
        }}
        elevation={5}
      >
        <Typography
          className="title"
          variant="h5"
          px={2}
          py={1}
          sx={{ borderColor: "borderLine.main" }}
        >
          <Box className="titleHead">
            <BackButton />
            <b>
              Assignment {assignmentID}:{" "}
              {assignmentData[0]?.type === "cw" ? "Classwork" : "Homework"}
            </b>
          </Box>
        </Typography>

        <Box className="customCardContent">
          {assignmentData.length > 0 ? (
            <>
              <Grid container>
                <Grid item md={4} xs={12} className="subHeadings">
                  <span className="field">Class:</span>{" "}
                  {assignmentData[0]?.classsection}
                </Grid>

                <Grid item md={4} xs={12} className="subHeadings">
                  <span className="field">Assigned On: </span>{" "}
                  {assignmentData[0]?.assignedon}
                </Grid>

                <Grid item md={4} xs={12} className="subHeadings">
                  <span className="field">Expected Completion:</span>{" "}
                  {assignmentData[0]?.expectedcompletion}
                </Grid>
              </Grid>
              <DataGrid
                rows={rows}
                columns={columns}
                 
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                componentsProps={{ columnsPanel: { disableHideAllButton: true } }}
                pageSizeOptions={[10, 25, 50, 100]}
                style={{ width: "100%" }}
              />
            </>
          ) : (
            <Typography
              sx={{
                color: "red",
                padding: "10px 40px",
                fontFamily: "Helvetica",
              }}
            >
              No Data Found
            </Typography>
          )}
        </Box>
      </Card>
    </Container>
  );
}

export default Assignment;

import { Box } from "@mui/material";
import React, { useContext } from "react";
import "./calendar.scss";
import { languages } from "../../App";
import CalendarData from "./CalendarData";
import MainCard from "../../_Components/Common/Cards/MainCard";
import { Scrollbar } from "react-scrollbars-custom";

function Calendar() {
  let curLanguage = useContext(languages);

  return (
    <MainCard cardTitle={curLanguage.calendar} mainPage>
      <Scrollbar style={{ width: "100%", height: `calc(72vh - 10px)` }}>
        <Box className="calendar-container">
          <CalendarData />
        </Box>
      </Scrollbar>
    </MainCard>
  );
}
export default Calendar;

import {
  Avatar,
  Box,
  Button,
  Container,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import CustomDialog from "../Common/CustomDialog/CustomDialog";
import Login from "../Login/Login";
import { Link, Navigate, useLocation } from "react-router-dom";
import logo from "../../images/logo.png";
import style from "./Header.module.scss";
import { Logout } from "@mui/icons-material";
import MenuLinks from "./MenuLinks";
import { usePermissions } from "../Permission/PermissionsContext";
import Notification from "../../_pages/Notification/Notification";
import { IoCloseCircleOutline } from "react-icons/io5";

var userInfo=null;
export default function Header(props) {
  const token = props.token;
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (token) {
      const fetchUserInfo = async () => {
        try {
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          alert(error.message);
        }
      };

      fetchUserInfo();
    } else {
      setIsLoading(false);
    }
  }, [token]);

  let headerComponent;

  if (isLoading) {
    headerComponent = <Header1 />; // Render Header1 while data is loading
  } else if (token) {
    headerComponent = <Header2 />; // Render Header2 if token is not null
  } else {
    headerComponent = <Header1 />; // Render Header1 if token is null
  }

  return <>{headerComponent}</>;
}

function Header1() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [disableOption, setDisableOption] = useState();
  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const handleLoginBtn = (values) =>{
    setDisableOption(!values)
    console.log(values, "valuesvalues")
  }
  return (
    <Box
      className={`${style.headerWrapper} ${style.homePage} headSec`}
      sx={{ backgroundColor: "themeColor.bgColor" }}
    >
      <Container maxWidth="xl" className={style.headerContent}>
        <Box className={style.logoCover}>
          <Link rel="preload" to="/">
            <img src={logo} alt="Ratna Sagar logo" />
          </Link>
        </Box>
        <Box className={style.headerRightContent}>
          <Link
            rel="preload"
            onClick={openDialog}
            className={`${style.btn} ${style.primaryBtn}`}
          >
            Login
          </Link>
        </Box>
        <CustomDialog
          isOpen={isDialogOpen}
          className={style.customDialog}
          width="xs"
        >
          <IconButton
            aria-label="close"
            onClick={closeDialog}
            disabled={disableOption}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <IoCloseCircleOutline size={40} color="#9fa2ab" />
          </IconButton>
        
          <Login loginBtnClick={handleLoginBtn}/>
        </CustomDialog>
      </Container>
    </Box>
  );
}

function Header2(props) {
  const token = props.token;
  const userInfo1 = JSON.parse(localStorage.getItem("userInfo"));
  userInfo = userInfo || {...userInfo1};
  const classInfo = JSON.parse(localStorage.getItem("classInfo"));
  const profileImg = localStorage.getItem("profileImg");
  const department =
    userInfo?.[0].department.toLowerCase() === "pedagogy"
      ? "Teacher"
      : userInfo?.[0].department.toLowerCase();

  const location = useLocation();
  const home = location.pathname === "/";
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { permissions } = usePermissions();
  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
    return <Navigate to="/" />;
  };
  
  return (
    <Box
      className={`${style.headerWrapper} ${style.headerWrapper2} headSec ${
        home ? style.homePage : style.otherPage
      }`}
      sx={{ backgroundColor: "themeColor.bgColor" }}
    >
      <Container maxWidth="xl" className={style.headerContent}>
        <Box className={`${style.logoCover} mainLogo`}>
          <Link rel="preload" to="/dashboard">
            <img src={logo} alt="Ratna Sagar logo" loading="lazy" />
          </Link>
        </Box>
        <Box className={style.headerRightContent}>
          <Box display={"flex"} alignItems={"center"}>
            {permissions?.Menu?.read && <MenuLinks />}
            <Notification />
            {/* <AlertMssg /> */}
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar
                  sx={{
                    width: 40,
                    height: 40,
                    backgroundColor: "secondaryColor.main",
                  }}
                >
                  <img
                    src={`${
                      userInfo?.length < 0
                        ? `${process.env.REACT_APP_wsPrefix}/lms-assets/images/defaultuser.png`
                        : profileImg
                        ? profileImg
                        : userInfo?.[0]?.profileimageurl
                    }`}
                    alt="profile-img"
                    width={"100%"}
                    loading="lazy"
                  />
                </Avatar>
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id={style.accountMenu}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          className={style.customDropdown}
          props={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              minWidth: "250px",
              "& .MuiAvatar-root": {
                width: 55,
                height: 55,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <Box className={style.userDetails} p={2}>
            <Box className={style.userData}>
              <Avatar
                sx={{
                  backgroundColor: "secondaryColor.main",
                  fontSize: "28px",
                  "& .MuiAvatar-root": {
                    width: 55,
                    height: 55,
                  },
                }}
              >
                {userInfo?.[0].fullname.charAt(0)}
              </Avatar>
            </Box>
            <Typography style={{ textAlign: 'center' }}>
              Hello, <b>{userInfo?.[0].fullname}!</b>
            </Typography>
            <Typography style={{ textAlign: 'center' }}>
              {userInfo?.[0].department.toLowerCase() === "student" && (
                <>
                  Class:{" "}
                  <b>
                    {classInfo?.class}
                    {classInfo?.section}
                  </b>
                </>
              )}{" "}
              {department}
            </Typography>

            <Button
              component={Link}
              to="/profile"
              className={style.manageBtn}
              sx={{
                mt: 2,
                color: "secondaryColor.main",
                borderColor: "secondaryColor.main",
              }}
              
            >
              View Profile
            </Button>
            <Button
              onClick={handleLogout}
              className="btn"
              variant="contained"
              sx={{
                mt: 2,
                backgroundColor: "secondaryColor.main",
                color: "#fff",
                borderRadius: "25px",
              }}
              
            >
              <Logout style={{ color: "#fff", marginRight: "5px" }} />
              Logout
            </Button>
          </Box>
        </Menu>
      </Container>
    </Box>
  );
}

import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material";
import {
  PrimaryBgBtn,
  PrimaryOutlineBtn,
} from "../../../_Components/Common/Buttons/CustomBtns";
import { useState } from "react";
import MainCard from "../../../_Components/Common/Cards/MainCard";

const AttendanceFormat = () => {
  const [checked, setChecked] = useState(false);
  const [customLabel, setCustomLabel] = useState("");
  const [selectedOptions, setSelectedOptions] = useState({
    Pr: false,
    Ab: false,
    Ha: false,
    Ex: false,
    Ho: false,
  });
  const [isCustomChecked, setIsCustomChecked] = useState(false);

  const getCustomData = () => {
    setChecked((prev) => !prev);
  };

  const handleLabelChange = (e) => {
    setCustomLabel(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setSelectedOptions((prev) => ({
      ...prev,
      [value]: checked,
    }));
  };

  const handleCustomCheckboxChange = (e) => {
    setIsCustomChecked(e.target.checked);
  };

  const handleSaveFormat = (e) => {
    e.preventDefault();
    const selectedValues = Object.keys(selectedOptions).filter(
      (key) => selectedOptions[key]
    );
    if (isCustomChecked && customLabel && checked) {
      const customAbbreviation = customLabel.slice(0, 2).toUpperCase();
      selectedValues.push(customAbbreviation);
    }
    console.log("Selected options:", selectedValues);
  };

  return (
    <MainCard cardTitle="Attendance Format">
      <form onSubmit={handleSaveFormat}>
        <FormControlLabel
          control={<Checkbox value="Pr" onChange={handleCheckboxChange} />}
          label="Present"
        />
        <FormControlLabel
          control={<Checkbox value="Ab" onChange={handleCheckboxChange} />}
          label="Absent"
        />
        <FormControlLabel
          control={<Checkbox value="Ha" onChange={handleCheckboxChange} />}
          label="Half Day"
        />
        <FormControlLabel
          control={<Checkbox value="Ex" onChange={handleCheckboxChange} />}
          label="Excused"
        />
        <FormControlLabel
          control={<Checkbox value="Ho" onChange={handleCheckboxChange} />}
          label="Holiday"
        />

        {checked && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleCustomCheckboxChange}
                  checked={isCustomChecked}
                />
              }
            />
            <TextField
              onChange={handleLabelChange}
              value={customLabel}
              id="outlined-basic"
              label=""
              variant="outlined"
            />
          </>
        )}
        <Box sx={{ textAlign: "center", marginTop: "20px"  }}>
          <PrimaryOutlineBtn onClick={getCustomData} style= {{ marginRight: "10px"}}>
            {checked ? "Remove Option" : "Add Option"}
          </PrimaryOutlineBtn>
          <PrimaryBgBtn type="submit">Submit</PrimaryBgBtn>
        </Box>
      </form>
    </MainCard>
  );
};

export default AttendanceFormat;

import { Box, Grid, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { usePermissions } from "../../_Components/Permission/PermissionsContext";
import Assigncounts from "./Assigncounts";

function QuickLinkItem({
  text,
  icon,
  assignmentCounts,
  permissionName,
  classDetail,
}) {
  const { permissions } = usePermissions();

  return (
    <>
      {(permissions[permissionName]?.read ||
        permissions[permissionName]?.write) && (
        <Box
          className="quickLink"
        >
          <Box
            className="quickLinks--item"
            sx={{ flexDirection: "column" }}
          >
            <Box className="imgBox">
              <img
                src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/${icon}`}
                alt={text}
              />
              {assignmentCounts !== undefined && assignmentCounts !== null && assignmentCounts>0 ? (
                <Typography className="count">
                  {assignmentCounts > 9 ? "9+" : assignmentCounts}
                </Typography>
              ) : null}
            </Box>
            <Box className="text">{text}</Box>
            <Box className="overlay">
              <Box className="overlayTxt">
                <Typography className="subject">{text}</Typography>
                {classDetail && <Typography>{classDetail}</Typography>}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

export default QuickLinkItem;


import MainCard from "../../_Components/Common/Cards/MainCard";
import CreateFinalResult from "../../_Components/Common/ReportCard/CreateFinalReport";
import { usePermissions } from "../../_Components/Permission/PermissionsContext";
const ReportCard = () => {
  const { permissions } = usePermissions();
  const getuserInfo = localStorage.getItem("userInfo")
  const userInfo = JSON.parse(getuserInfo)
  let userDepartment = userInfo[0].department.toLowerCase()
  return (
    <MainCard cardTitle="Report Card" customClass="">
      {permissions.ReportCard?.write && <CreateFinalResult/>}
      {/* {permissions.ReportCard?.read && <StudentReportCard/>} */}

     {/* <CreateFinalResult/> */}
 {/* <StudentReportCard/> */}
    {/* {
      userDepartment === 'Pedagogy'? <CreateFinalResult/>:<StudentReportCard/>
    } */}

     {/* <StudentProfile/> */}
     {/* <StudentReportCard/> */}
      {/* <StudentProfile />
      <GuidelinesForParents/>
      <GradingScales/>
      <CoScholasticProfile/>
      <ScholasticAssessmentTerm1/>
      <ScholasticAssessmentTerm2/>
      <FinalResult/> */}
      {/* <FinalResult/> */}
    </MainCard>


  );
};

export default ReportCard;

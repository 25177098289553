import React, { useEffect, useState } from 'react';
import { Box, MenuItem, Select, FormControl, InputLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import axios from 'axios';
import MainCard from '../Cards/MainCard';
function ViewModuleTable() {
    const [data, setData] = useState(false);
    const [selectedTitle, setSelectedTitle] = useState('');
    const [selectedData, setSelectedData] = useState(null);
  
    const handleChange = (event) => {
      const title = event.target.value;
      setSelectedTitle(title);
      const selected = data.find(item => item.title === title);
      setSelectedData(selected);
    };
    const nToken = localStorage.getItem("nToken");
    const getuserInfo = localStorage.getItem("userInfo")
    const getfinancialYear = localStorage.getItem("AY")
    var financialYear = JSON.parse(getfinancialYear)
    const userInfo = JSON.parse(getuserInfo)
    var schoolcode = userInfo[0].institution
    console.log(schoolcode, financialYear);
    // useEffect(() => {
    //     const url = `${process.env.REACT_APP_nodewsPrefix}/viewDynamicTable?schoolcode=${schoolcode}`
    //     axios.get(url, {
    //         headers: {
    //             Authorization: `Bearer ${nToken}`
    //         }
    //     }).then((response) => {
    //         setData(response.data.module_data)
    //     });
    // }, [])
    console.log(data);
  return (
    <MainCard cardTitle="Report Card" customClass="">
    <Box sx={{ p: 3 }}>
    <FormControl fullWidth>
      <InputLabel id="select-label">Select Title</InputLabel>
      <Select
        labelId="select-label"
        value={selectedTitle}
        onChange={handleChange}
        label="Select Title"
      >
        {data && data.map((item) => (
          <MenuItem key={item.title} value={item.title}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>

    {selectedData && (
      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              {selectedData.columnName.map((col, index) => (
                <TableCell key={index}>{col}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {selectedData.customColumn.map((col, index) => (
                <TableCell key={index}>{col}</TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )}
  </Box>
  </MainCard> 
  )
}
export default ViewModuleTable
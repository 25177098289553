import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { languages } from "../../App";
import "./StdCertificates.scss";
import Loader from "../../_Components/Utils/loader/Loader";
import { Scrollbar } from "react-scrollbars-custom";
import DownloadCertificate from "../Certificate/DownloadCertificate";
import MainCard from "../../_Components/Common/Cards/MainCard";

function StdCertificates() {
  let curLanguage = useContext(languages);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [certificates, setCertificates] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const nToken = localStorage.getItem("nToken");

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_nodewsPrefix}/getstdAllcertificate?studentid=${userinfo[0].id}`,
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        setCertificates(response.data);
      });
  }, []);

  return (
    <MainCard cardTitle={curLanguage.certificate} mainPage>
        <Scrollbar
          style={{ width: "100%", height: 671 }}
          className="customCardItem"
        >
          {isLoading ? (
            <Loader />
          ) : (
            <DownloadCertificate certificates={certificates} />
          )}
        </Scrollbar>
      </MainCard>
  );
}
export default StdCertificates;

import { Box } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import ListingCard from "../../_Components/Common/Cards/ListingCard/ListingCard";
import { Scrollbar } from "react-scrollbars-custom";
import "./AnnualPlanner.scss";
import CardSecondary from "../../_Components/Common/Cards/CardSecondary";

const ExamSheetList = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [upcomingEvent, setUpcomingEvent] = useState([]);
  useEffect(() => {
    const getUpcomingData = () => {
      axios
        .get(
          `${process.env.REACT_APP_nodewsPrefix}/viewExamEvent?usertype=${userInfo[0]?.department}&schoolcode=${userInfo[0]?.institution}`
        )
        .then((response) => {
          setUpcomingEvent(response.data.msg);
        });
    };

    getUpcomingData();
  }, []);

  const groupEventPlanner = (upcomingEventData) => {
    const groupEvent = [];
    upcomingEventData?.forEach((cert) => {
      const eventName = cert.name;
      if (!groupEvent[eventName]) {
        groupEvent[eventName] = [];
      }
      groupEvent[eventName].push(cert);
    });

    return groupEvent;
  };

  const upcomingEventInfo = groupEventPlanner(upcomingEvent);

  return (
    <CardSecondary curLanguage="Upcoming Exam">
      <Scrollbar style={{ width: "100%", height: `calc(53vh - 20px)` }}>
        {upcomingEventInfo ? (
          <>
            {Object.keys(upcomingEventInfo).map((examType, index) => (
              <Box key={index}>
                <ListingCard title={examType}>
                  {upcomingEventInfo[examType].map((item, itemIndex) => (
                    <Box key={itemIndex}>
                      Class: {item.class_name}
                      <br />
                      Subject: {item.subject_name}
                    </Box>
                  ))}
                </ListingCard>
              </Box>
            ))}
          </>
        ) : (
         <Box mt={1}>
           <ListingCard>No exam date has been scheduled.</ListingCard>
         </Box>
        )}
      </Scrollbar>
    </CardSecondary>
  );
};

export default ExamSheetList;

import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Scrollbar } from "react-scrollbars-custom";
import "./SavedCertificates.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import MainCard from "../../_Components/Common/Cards/MainCard";
import DownloadCertificate from "./DownloadCertificate";

const SavedCertificates = () => {
  const [certificates, setCertificates] = useState([]);
  const nToken = localStorage.getItem("nToken");
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [classVal, setClassVal] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setClassVal(typeof value === "string" ? value.split(", ") : value);
  };

  useEffect(() => {
    const fetchCertificates = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_nodewsPrefix}/getTeacherAllcertificate?teacherid=${userinfo[0].id}`,
          {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          }
        );

        const certs = response.data.info;
        setCertificates(certs);
        const uniqueClassSections = [
          ...new Set(certs.map((data) => data.classsec)),
        ];
        setClassVal(uniqueClassSections);
      } catch (error) {
        console.error("Error fetching certificates:", error);
      }
    };
    fetchCertificates();
  }, []);
  const groupCertificatesByClassSec = (certificates) => {
    const groupedCertificates = {};

    certificates?.forEach((cert) => {
      const classsec = cert.classsec;
      if (!groupedCertificates[classsec]) {
        groupedCertificates[classsec] = [];
      }
      groupedCertificates[classsec].push(cert);
    });

    return groupedCertificates;
  };

  const groupedCertificates = groupCertificatesByClassSec(certificates);

  return (
    <MainCard
      cardTitle="Saved Certificates"
      additionalData={
        certificates ? (
          <Box width={"200px"}>
            <FormControl fullWidth>
              <InputLabel>Class</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                multiple
                label="Class"
                value={classVal}
                onChange={handleChange}
                renderValue={(selected) =>
                  Array.isArray(selected) ? selected.join(", ") : ""
                }
              >
                {[
                  ...new Set(
                    certificates
                      .map((data) => data.classsec)
                      .sort((a, b) => a - b)
                  ),
                ].map((classsec, id) => (
                  <MenuItem value={classsec} key={id}>
                    <Checkbox checked={classVal.indexOf(classsec) > -1} />
                    {classsec}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        ) : (
          ""
        )
      }
    >
      <Scrollbar
        style={{ width: "100%", height: `calc(70vh - 40px)` }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {certificates ? (
          <>
            {classVal.length ? (
              classVal.map((selectedClasssec, index) =>
                groupedCertificates[selectedClasssec]?.length ? (
                  <Box key={index} className="certificateClass">
                    <Typography my={2}>
                     <b> Class: {selectedClasssec}</b>
                    </Typography>
                    <DownloadCertificate certificates = {groupedCertificates[selectedClasssec] }/>
                   
                  </Box>
                ) : (
                  <Box key={index}>
                    <Typography textAlign="center">
                      No certificates available for class {selectedClasssec}.
                    </Typography>
                  </Box>
                )
              )
            ) : (
              <Box>
                <Typography textAlign="center">No class selected.</Typography>
              </Box>
            )}
          </>
        ) : (
          <Typography textAlign="center" className="bgText">You haven't generated any certificates for any class so far.</Typography>
        )}
      </Scrollbar>
    </MainCard>
  );
};

export default SavedCertificates;

import React, { useEffect, useState } from 'react';
import {
    Box, Typography, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, TextField, Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';

const FinalResult = () => {
    const [isEditable, setIsEditable] = useState(false);
    const [grades, setGrades] = useState({});
    const [subjects, setSubjects] = useState([]);
    const [columns, setColumns] = useState([]);
  
    const [data, setData] = useState([]);
    const [selectedFormCode, setSelectedFormCode] = useState('');
    const [title, setTitle] = useState(selectedFormCode);
    const nodeURL = "http://10.4.40.20:4000/";

    const handleEditToggle = () => {
        setIsEditable(!isEditable);
        if (isEditable) {
            submit();
        }
    };

    const handleGradeChange = (subject, column) => (event) => {
        const newGrades = { ...grades };
        newGrades[subject][column] = event.target.value;
        setGrades(newGrades);
    };

    const handleAddRow = () => {
        // setIsEditable(!isEditable);
         if(selectedFormCode){
            setIsEditable(true)
            const newSubject = `New Row ${subjects.length + 1}`;
            const newGrades = { ...grades, [newSubject]: {} };
            columns.forEach(column => {
                newGrades[newSubject][column] = '';
            });
            setSubjects([...subjects, newSubject]);
            setGrades(newGrades);
         }
        
        
    };

    const handleDeleteRow = async(subject,index) => {
       const rowID=parseInt(subject.replace("New Row ",""))
       console.log(typeof rowID);

        // const url=nodeURL+`deleteTableRow?suffix=${urlname}&schoolcode=${schoolcode}&rowid=${rowID}&formcode=${selectedFormCode}`
           const url=`${process.env.REACT_APP_nodewsPrefix}/deleteTableRow?suffix=${urlname}&schoolcode=${schoolcode}&rowid=${rowID}&formcode=${selectedFormCode}`
        await axios.delete(url, {
            headers: {
                Authorization: `Bearer ${nToken}`
            }
        }).then((response) => {
            console.log(response.data);
         
        });



        const newSubjects = subjects.filter(sub => sub !== subject);
        const newGrades = { ...grades };
        delete newGrades[subject];
        setSubjects(newSubjects);
        setGrades(newGrades);
    };

    function createSequences(subjects, grades) {
        let result = {};
        subjects.forEach(subject => {
            if (grades[subject]) {
                result[subject] = grades[subject];
            }
        });
        return result;
    }

    const sequences = createSequences(subjects, grades);
    localStorage.setItem("FinalData", JSON.stringify(sequences));
    const nToken = localStorage.getItem("nToken");
    const getuserInfo = localStorage.getItem("userInfo")
    const getfinancialYear = localStorage.getItem("AY")
    const userInfo = JSON.parse(getuserInfo)
    const schoolcode = userInfo[0].institution
    let userDepartment = userInfo[0].department.toLowerCase()
    if (userDepartment === 'Pedagogy') {
        userDepartment = 1
    }

    const location = useLocation();
    let urlname = location.pathname;
    if (urlname === '/report') {
        urlname = 'rC'
    }

    useEffect(() => {
        const url = `${process.env.REACT_APP_nodewsPrefix}/readDynamicTable?suffix=${urlname}&schoolcode=${schoolcode}&academic_year=${getfinancialYear}&teacher=${userDepartment}`;
        // const url=nodeURL+`readDynamicTable?suffix=${urlname}&schoolcode=${schoolcode}&academic_year=${getfinancialYear}&teacher=${userDepartment}`
        axios.get(url, {
            headers: {
                Authorization: `Bearer ${nToken}`
            }
        }).then((response) => {
            setData(response.data.formData)
        });
    }, []);

    const handleFormCodeChange = (event) => {
        const formCode = event.target.value;
        setSelectedFormCode(formCode);

        const selectedForm = data.find(form => form.formcode === formCode);
        if (selectedForm) {
            const formColumns = selectedForm.entries.map(entry => entry.columnName);
            setColumns(formColumns);

            const formRows = selectedForm.rows || {};
            setGrades(formRows);
            setSubjects(Object.keys(formRows));
        } else {
            setColumns([]);
            setGrades({});
            setSubjects([]);
        }
    };

    async function submit() {
        // const url = nodeURL +'insertTableRow';
        
        const url = `${process.env.REACT_APP_nodewsPrefix}/insertTableRow`;
        await axios.post(url, {
            "formcode": selectedFormCode,
            "suffix": urlname,
            "academic_year": getfinancialYear,
            "schoolcode": schoolcode,
            "insert_data": sequences
        }, {
            headers: {
                Authorization: `Bearer ${nToken}`
            }
        });
    }

    return (
        <Box sx={{ padding: '2rem' }}>
            <FormControl fullWidth margin="normal">
                <InputLabel id="formcode-label">Form Code</InputLabel>
                <Select
                    labelId="formcode-label"
                    id="formcode-select"
                    value={selectedFormCode}
                    onChange={handleFormCodeChange}
                    label="Form Code"
                >
                    {data.map((form) => (
                        <MenuItem key={form.formcode} value={form.formcode}>
                            {form.formcode}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Box sx={{ marginBottom: '2rem' }}>
                <Box>
                    <Box>
                        <Typography variant="h3">{title}</Typography>
                    </Box>
                    <Box>
                      
                        <Box>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {columns && columns.map((column, index) => (
                                                <TableCell align="left" key={index} >
                                                    <Typography variant="h6">{column}</Typography>
                                                </TableCell>
                                            ))}
                                            {isEditable && (
                                                <TableCell align="center">
                                                    <Typography variant="h4">Actions</Typography>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {subjects && subjects.map((subject, index) => (
                                            <TableRow key={index}>
                                                {columns && columns.map((column) => (
                                                    <TableCell align="center" key={column} className="text-center p-0">
                                                        <TextField
                                                            readOnly={!isEditable}
                                                            value={grades[subject][column]}
                                                            onChange={handleGradeChange(subject, column)}
                                                            variant="standard"
                                                            InputProps={{
                                                                disableUnderline: !isEditable,
                                                                className: 'w-100 text-center'
                                                            }}
                                                        />
                                                    </TableCell>
                                                ))}
                                              
                                                    <TableCell align="center">
                                                        <IconButton onClick={() => handleDeleteRow(subject,index)} color="error">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Button onClick={handleAddRow} variant="contained" sx={{ marginBottom: '1rem' }}>
                            Add Row
                        </Button>
            <Button  onClick={handleEditToggle} variant="contained" sx={{ marginBottom: '1rem', marginLeft: '1rem' }}>
                            {isEditable ? 'Save' : 'Edit'}
                        </Button>
                       
        </Box>
    );
};

export default FinalResult;

import { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, Card, Container, Grid, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import MainBackBtn from "../../_Components/Common/BackBtn/MainBackBtn";
import CircularLoader from "../../_Components/Common/Loader/CircularLoader";
import { usePermissions } from "../../_Components/Permission/PermissionsContext";

function Assignments() {
  const { permissions } = usePermissions();
  const [viewAssessment, setViewAssessment] = useState([]);
  const courseinfo = localStorage.getItem("courseDetails");
  const courseinfoObject = JSON.parse(courseinfo);
  const userInfoData = localStorage.getItem("userInfo");
  const userinfoObject = JSON.parse(userInfoData);
  const [isLoading, setIsLoading] = useState(false);
  const nToken = localStorage.getItem("nToken");
  const mToken = localStorage.getItem("mToken");
  const stdId = JSON.parse(localStorage.getItem("classInfo"))?.classid;

  const columnsStudent = [
    {
      field: "Status",
      headerName: "Action",
      width: 100,
      renderCell: (cellValues) => {
        return (
          <Button
            id={cellValues.row.scoid}
            variant="contained"
            sx={{
              backgroundColor: cellValues.row.status === "Submited" ? "#0f7700" : "#2769aa",
              color: "secondaryColor.contrastText",
            }}
            onClick={() =>
              openLinkInTab(
                cellValues.row.scoid,
                stdId,
                cellValues.row.assignmentid
              )
            }
          >
            {console.log(cellValues, "cellValues")}
            <Typography>Go</Typography>
          </Button>
        );
      },
    },
    { field: "assignmentName", headerName: "Assignment Name", width: 250 },
    { field: "assignmentType", headerName: "Assignment Type", width: 150 },
    { field: "courseSubject", headerName: "Subject", width: 100 },
    { field: "assignedOn", headerName: "Assigned On", width: 180 },
    { field: "submitedOn", headerName: "Submited On", width: 180 },
    {
      field: "expectedcompletion",
      headerName: "Expected Completion",
      width: 180,
    },
    { field: "status", headerName: "Status", width: 150 },
    { field: "maxmarks", headerName: "Marks" },
    /* { field: "submissions", headerName: "Submissions" } */
  ];

  let columns;

  const columnsTeacher = [
    {
      field: "Status",
      headerName: "Action",
      hideable: false,
      width: 100,
      renderCell: (cellValues) => {
        return (
          <Button
            id={cellValues.row.scoid}
            state={cellValues.row.classid}
            variant="contained"
            sx={{
              backgroundColor: cellValues.row.status === "submitted" ? "green" : "#2769aa",
              color: "secondaryColor.contrastText",
            }}
            onClick={() =>
              openLinkInTab(cellValues.row.scoid, cellValues.row.classid, cellValues.row.assignmentid, cellValues.row.assignSubmit)
            }
          >
            <Typography>Go</Typography>
          </Button>
        );
      },
    },
    { field: "assignmentName", headerName: "Name", width: 300,  align: 'left', headerAlign: 'left' },
    { field: "assignmentType", headerName: "Type", width: 150 },
    { field: "courseSubject", headerName: "Subject", width: 200 },
    { field: "class", headerName: "Class", width: 120 },
    { field: "assignedOn", headerName: "Assigned On", width: 200 },
    { field: "maxmarks", headerName: "Marks", width: 150, type: "number", align: 'left', headerAlign: 'left' },
    { field: "assignSubmit", headerName: "Submit Count", width: 150, type: "number", align: 'left', headerAlign: 'left' },
    { field: "questioncount", headerName: "Question Count", width: 150, type: "number", align: 'left', headerAlign: 'left' },
  ];

  if (userinfoObject[0].department.toLowerCase()=== "pedagogy") {
    columns = columnsTeacher;
  } else {
    columns = columnsStudent;
  }

  function filterUniqueAssignmentIds(data) {
    const uniqueAssignments = {};
    data.forEach((item) => {
      const assignmentid = item.assignmentuniqueid;
      if (!uniqueAssignments[assignmentid]) {
        uniqueAssignments[assignmentid] = item;
      }
    });

    return Object.values(uniqueAssignments);
  }

  let modifiedViewAssessment = [];

  if (viewAssessment.data) {
    modifiedViewAssessment = filterUniqueAssignmentIds(viewAssessment.data);
  }

  let rows;

  const rowsTeacher =
    viewAssessment.data && Array.isArray(viewAssessment.data)
      ? modifiedViewAssessment.map((values, indx) => ({
        id: indx,
        courseid: values.courseid,
        assignmentName: `${values.scromname} Assignment ${values.assignmentuniqueid}`,
        assignmentType: values.type === "cw" ? "Classwork" : "Homework",
        courseSubject: values.coursename,
        questionCount: values.questioncount,
        assignedOn: values.assignedon,
        expectedcompletion: values.expectedcompletion,
        submitedOn: values.timecreated,
        class: values.classsection,
        assignSubmit: values.assignment_submit_count,
        status: values.submitstatus ? "Submited" : "Not Attempted",
        maxmarks: values.maxmarks,
        questioncount: values.questioncount,
        scoid: values.scoid,
        classid: values.classid,
        assignmentid: values.assignmentuniqueid,
      }))
      : [];

  const rowsStudent =
    viewAssessment.data && Array.isArray(viewAssessment.data)
      ? viewAssessment.data.map((values, indx) => ({
        id: indx,
        courseid: values.courseid,
        assignmentName: `${values.scormname} Assignment ${values.assignmentuniqueid}`,
        assignmentType: values.type === "cw" ? "Classwork" : "Homework",
        courseSubject: values.coursename,
        questionCount: values.questioncount,
        assignedOn: values.assignDate,
        expectedcompletion: values.expectedcompletion,
        submitedOn: values.timecreated,
        class: values.classsection,
        status: values.submitstatus ? "Submited" : "Not Attempted",
        maxmarks: values.maxmarks,
        questioncount: values.questioncount,
        scoid: values.scoid,
        assignmentid: values.assignmentid,
      }))
      : [];

  if (userinfoObject[0].department.toLowerCase() === "student") {
    rows = rowsStudent;
  } else {
    rows = rowsTeacher;
  }

  useEffect(() => {
    if (userinfoObject[0].department.toLowerCase() === "pedagogy") {
      setIsLoading(true);
      const v_assessmentURL = `${process.env.REACT_APP_nodewsPrefix}/getAssignedHWCWByTeacherCourse`;
      axios
        .post(
          v_assessmentURL,
          {
            userid: userinfoObject[0].id,
            schoolcode: userinfoObject[0].institution,
            // courseid: courseinfoObject[0].id,
          },
          {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          }
        )
        .then((response) => {
          setIsLoading(false);
          setViewAssessment(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setIsLoading(true);
      const v_assessmentURL = `${process.env.REACT_APP_nodewsPrefix}/getAssignedHWCWByStudentCourse`;
      axios
        .post(
          v_assessmentURL,
          {
            userid: userinfoObject[0].id,
            // courseid: courseinfoObject[0].id,
            classid: stdId,
            schoolcode: userinfoObject[0].institution,
          },
          {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          }
        )
        .then((response) => {
          setIsLoading(false);
          setViewAssessment(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, []);

  const setLink = (scoid, scormid, classid) => {
    let links;
    links = `${process.env.REACT_APP_wsPrefix}/mod/scorm/scormplayer.php?a=${scoid}&currentorg=ratnasagar&scoid=${scormid}&sesskey=hP1eN08thy&display=popup&mode=normal&b-token=${nToken}&token=${mToken}&cid=${classid}`;
    return links;
  };

  const openLinkInTab = async (scoid, classid, assignmentID, submitCount) => {
    // var scoid = scoid;
    try {
      await axios
        .get(`${process.env.REACT_APP_nodewsPrefix}/getScoid?scormid=${scoid}`)
        .then((response) => {
          window.open(setLink(scoid, response.data.id, classid), "");
        });

      permissions.classes?.write &&
        (await axios

          .post(
            `${process.env.REACT_APP_nodewsPrefix}/updateAssignedHWCWByTeacherCourse`,

            {
              userid: userinfoObject[0].id,
              assignment_id: assignmentID,
              submitcount: submitCount,
            },
            {
              headers: {
                Authorization: `Bearer ${nToken}`,
              },
            }
          )
          .then((response) => {
            console.log(response.data);
          }));

      permissions.courses?.write &&
        (await axios

          .post(
            `${process.env.REACT_APP_nodewsPrefix}/UpdateAssignedCountHWCWStudent`,

            {
              userid: userinfoObject[0].id,
              assignment_id: assignmentID,
            },
            {
              headers: {
                Authorization: `Bearer ${nToken}`,
              },
            }
          )
          .then((response) => {
            console.log(response.data);
          }));
    } catch {
      console.log("error");
    }
  };

  return (
    <Container maxWidth="xl">
      <Card
        className="customCard"
        sx={{
          mb: 4,
          backgroundColor: "cardColor.main",
          color: "cardColor.contrast",
        }}
        elevation={5}
      >
        <Typography
          className="title"
          variant="h5"
          px={2}
          py={1}
          sx={{ borderColor: "borderLine.main" }}
        >
          <Grid container sx={{ alignItems: "center" }}>
            <Grid item md={9} xs={9}>
              <Box className="titleHead">
                <MainBackBtn /> <b>Assignments</b>
              </Box>
            </Grid>
          </Grid>
        </Typography>

        {isLoading ? (
          <CircularLoader />
        ) : (
          <Box className="customCardContent">
            {viewAssessment.data && viewAssessment.data.length > 0 ? (
              <div style={{ height: 680, width: "100%" }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  componentsProps={{ columnsPanel: { disableHideAllButton: true } }}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10 },
                    },
                    // sorting: {
                    //   sortModel: [{ field: 'questioncount', sort: 'asc' }],
                    // },
                  }}
                  pageSizeOptions={[10, 25, 50, 100]}
                  style={{ width: "100%" }}
                />
              </div>
            ) : (
              <Box
                sx={{
                  color: "red",
                  padding: "10px 40px",
                  fontFamily: "Helvetica",
                }}
              >
                No Assingments found
              </Box>
            )}
          </Box>
        )}
      </Card>
    </Container>
  );
}
export default Assignments;

  // HindiLanguage.js
  const HindiLanguage = {

        "login": "लॉग इन",
        "username": "उपयोगकर्ता नाम",
        "password": "पासवर्ड",
        "forgetpassword": "पासवर्ड भूल गए",
        "name": "नाम",
        "download": "डाउनलोड",
        "recentactivities": "हाल ही में उपयोग की गई गतिविधियाँ",
        "norecentactivities": "कोई गतिविधि नहीं मिली",
        "recentcourses": " हाल के पाठ्यक्रम",
        "norecentcourses": "कोई गतिविधि नहीं मिली",
        "recentassignments": " हाल ही में एक्सेस किए गए असाइनमेंट",
        "norecentassignments": "कोई असाइनमेंट नहीं",


        "dashboard": "डैशबोर्ड",
        "bookmarks": "बुकमार्क",
        "settings": "समायोजन",
        // ===========================        
        "mycourses": "मेरे पाठ्यक्रम",
        "myclasses": "My Classes",
        "myattendance": "My Attendance",
        // ===========================
        "class": "कक्षा",

        // calendar
        "mycalendar": "मेरा कैलेंडर",
        "newevent": "नई इवैंट",
        "eventtitle": "कार्यक्रम का शीर्षक",
        "eventdate": "तारीख",
        "eventtype": "घटना प्रकार",
        "eventdescription": "विवरण",
        "eventlocation": "जगह",
        "eventduration": "अवधि",

        "save": "बचाना",
        "delete": "मिटाना",
        "edit": "संपादन करना",
        "close": "बंद करना",
        "cancel": "रद्द",
        "showmore": "और दिखाओ",
        "back": "पीछे",

        //home page section folder
        "Downloadbutton": " डाउनलोड",
        "learnMore": "और अधिक जानें",

        //Footer Folder
        "readMore": "और पढ़ें",
        "visitUs": "हमसे मिलें",
        "aboutUs": "हमारे बारे में",
        "qualityPolicy": "गुणवत्ता नीति",
        "info": "जानकारी",
        "contactUs": "संपर्क करें",

        //popup  create events 
        "createNewEvent": "नया ईवेंट बनाएँ",
        "createEvent": "ईवेंट बनाएँ",
        "eventDate": "तारीख",
        "eventDateUntil": "दिनांक तक",
        "durationInMinutes": "अवधि मिनटों में",
        "description": "विवरण",
        "minutes": "मिनट",
        "repeatThisEvent": "Repeat this event",
        "repeatWeeklyEvent": "Repeat Weekly Event",

        //popup get event details 
        "date": "तारीख",
        "userName": "उपयोगकर्ता नाम",
        "deleteAllEvent": "सभी ईवेंट हटाएं",
        "areYouSure": "क्या आपको यकीन है।",

        // Assessment Attempt
        "previous": "पहले का",
        "next": "अगला",
        "QuizNavigation": "प्रश्नोत्तरी नेविगेशन",

        // create Calendar Event
        "Typeofevent": "ईवेंट का प्रकार",
        "course": "अवधि",
        "MySelf": "खुद",

        //user Popup
        "profile": "प्रोफ़ाइल",
        "grades": "ग्रेड",
        "calendar": "कैलेंडर",
        "messages": "संदेशों",
        "preferences": "पसंद",
        "Logout": "लॉग आउट",
        "YouAreloggedInAs": "आपने इसके रूप में लॉगिन किया है ",
        // user Profile
        "Userdetails": "उपयोगकर्ता विवरण",
        "editprofile": "प्रोफ़ाइल संपादित करें",
        "Emailaddress": "मेल पता",
        "Citytown": "शहर / कस्बा",
        "Loginactivity": "लॉगिन गतिविधि",
        "Firstaccesstosite": "साइट पर पहली पहुंच",
        "Lastaccesstosite": "साइट पर अंतिम पहुंच",
        "Coursedetails": "पाठ्यक्रम विवरण",

        // report
        "report": "प्रतिवेदन",
        "finished": "प्रस्तुत",
        "notattempted": "प्रस्तुत नही किया है",
        "review": "समीक्षा",
        "emailAddress": "मेल पता",
        "status": "स्तिथि",
        "started": "को प्रारंभ करें",
        "completed": "पर पूर्ण",
        "timetaken": "समय लिया",
        "grade": "श्रेणी %",
        "marks": "निशान/",
        "q1": "प्रश्न. 1",
        "q2": "प्रश्न. 2",
        "q3": "प्रश्न. 3",
        "q4": "प्रश्न. 4",
        "q5": "प्रश्न. 5",
        "q6": "प्रश्न. 6",
        "q7": "प्रश्न. 7",
        "q8": "प्रश्न. 8",

        "q9": "प्रश्न. 9",
        "q10": "प्रश्न. 10",
        "q11": "प्रश्न. 11",

        "q12": "प्रश्न. 12",
        "q13": "प्रश्न. 13",

        "q14": "प्रश्न. 14",
        "q15": "प्रश्न. 15",
        //question 
        "question": "सवाल",
        "answered": "उत्तर",
        "correct": "सही",
        "wrong": "ग़लत",
        "notatt": "प्रयास नहीं किया",
        "quizNavigation": "प्रश्नोत्तरी नेविगेशन",

        // Attendance
        "Totaldays": "कुल दिन",
        "Totalpresent": "कुल उपस्थिति",
        "Totalabsent": "कुल अनुपस्थित",
        "Totalleaves": "कुल छुट्टी",
        "markattendance": "मार्क उपस्थिति",


        //Admission Form Page        
        "fname": "पहला नाम",
        "lname": "उपनाम",
        "email": "ईमेल",
        "image": "अपलोड चित्र",
        "admissionform": "प्रवेश फार्म",
        "admissionno": "प्रवेश संख्या",
        "studentdetail": "छात्र का विवरण",
        "dob": "जन्म तिथि",
        "gender": "लिंग",
        "female": "महिला",
        "male": "पुरुष",
        "other": "अन्य",
        "category": "वर्ग",
        "general": "सामान्य",
        "obc": "अन्य पिछड़ा वर्ग",
        "sc": "अनुसूचित जाति",
        "st": "अनुसूचित जनजाति",
        "religion": "धर्म",
        "hindu": "हिन्दू",
        "muslim": "मुस्लिम",
        "christian": "ईसाई",
        "aadhar": "आधार कार्ड अपलोड करें",
        "photoupload": "फोटो अपलोड करें",
        "document": "दस्तावेज़ अपलोड करें",
        "address": "पता",
        "permanent": "स्थायी पता",
        "current": "वर्त्तमान पता",
        "addressline1": "पता पंक्ति 1",
        "addressline2": "पता पंक्ति 2",
        "city": "शहर",
        "state": "राज्य",
        "pincode": "पिन कोड",
        "country": "देश",
        "birthCertificate": "जन्म प्रमाणपत्र अपलोड करें",
        "parentdetails": "माता-पिता का विवरण",
        "mothername": "माता का नाम",
        "motheroccupation": "माता का व्यवसाय",
        "motherphone": "माता का फ़ोन नंबर",
        "fathername": "पिता का नाम",
        "fatheroccupation": "पिता का व्यवसाय",
        "fatherphone": "पिता का फ़ोन नंबर",
        "documents": "दस्तावेज़",
        "selectBoth": "चयन करें यदि दोनों पते समान हैं",
        "submit": "जमा करें ",
        "blood": "ब्लड ग्रुप",
        "blood1": "O+",
        "blood2": "O-",
        "blood3": "A+",
        "blood4": "A-",
        "blood5": "B+",
        "blood6": "B-",
        "blood7": "AB+",
        "blood8": "AB-",
        "nationality": "राष्ट्रीयता",
        "ews": "आर्थिक रूप से कमजोर वर्ग (ईडब्ल्यूएस)?",
        "sibling": "एक ही स्कूल में भाई-बहन?",
        "transport": "परिवहन आवश्यक है?",
        "guardian": "अभिभावक का नाम",
        "relation": "संतान के साथ संबंध",
        "schollname": "अंतिम विद्यालय का नाम",
        "schoolAddress": "अंतिम विद्यालय का पता",
        "physical": "शारीरिक रूप से विकलांग?",
        "mentally": "मानसिक रूप से विकलांग?",
        "illness": "कोई गंभीर बीमारी?",
        "descriptionIllness": "बीमारी का वर्णन",
        "ewscertificate": "ईडब्ल्यूएस प्रमाणपत्र अपलोड करें",
        "certificate": "प्रमाणपत्र",
        "section": "अनुभाग",
        "feereceipt": "शुल्क रसीद",
        "imgsize": "Img का आकार 120*120px मिनट होना चाहिए",
        "pancard": "पैन कार्ड अपलोड करें",
        "salutation": "अभिवादन",
        "fullname": "पूरा नाम",
        "highqualification": "उच्च योग्यता",
        "occupation": "पेशा",
        "annualIncome": "वार्षिक आय",
        "officeAddress": "कार्यालय का पता",
        "phoneNumber": "फ़ोन नंबर",
        "mobile": "मोबाइल",
        "contactNo": "संपर्क नंबर",
        "Parentsdetail": "अभिभावक का विवरण",
        "registrationNo": "पंजीकरण संख्या",

        //Notes Page
        "mynotes": "मेरे नोट्स",
        "title": "शीर्षक",
        "createdOn": "बनाया",
        "modifiedOn": "संशोधित",
        "action": "कार्य",

        //List items
        "mystuff": "मेरा क्षेत्र",
        "classes": "कक्षाओं",
        "attendance": "उपस्थिति",
        "notes": "नोट्स",
        "fees": "फीस",
        "admission": "प्रवेश",
        "courses": "पाठ्यक्रम",

        //Fee Collection        
        "feecollection": "शुल्क संग्रहण",
        "studentname": "छात्र का नाम",
        "createcertificate": "प्रमाणपत्र बनाएं",
        "viewcertificate": "प्रमाणपत्र देखें",
        "search": "खोजे",
        "notify": "सूचित करें",
        "receivefee": "शुल्क प्राप्त करें",

        //Create Certificate 
        "create": "बनाएँ",
        "enter": "दाखिल करें",

        //Exam Planner
        "examPlanner": "नियोजक",
        "timeTable": "समय सारणी",        
        "annualPlanner": "Annual Planner",
        "usermgmt": "User Management",
  
  };
  
  export default HindiLanguage;
import { Avatar, Button, ClickAwayListener, Menu } from "@mui/material";
import { CgMenuGridO } from "react-icons/cg";
import QuickLinks from "../../_pages/QuickLinks/QuickLinks";
import { useEffect, useRef, useState } from "react";
import "./MenuLinks.scss";

const MenuLinks = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  return (
    <div>
      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        aria-label="Menu Button"
      >
        <Avatar
          sx={{
            width: 40,
            height: 40,
            backgroundColor: "secondaryColor.main",
          }}
        >
          <CgMenuGridO size={30} />
        </Avatar>
      </Button>

      <Menu
       anchorEl={anchorRef.current}
       id="accountMenu"
       open={open}
       onClose={handleClose}
       // onClick={handleClose}
       className="customDropdown menuDropdown"
       props={{
         elevation: 0,
         sx: {
           overflow: "visible",
           filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
           minwidth: "250px",
           background: "cardColor.main",
           mt: 1.5,
           "& .MuiAvatar-root": {
             width: 55,
             height: 55,
           },
         },
       }}
       transformOrigin={{ horizontal: "right", vertical: "top" }}
       anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <QuickLinks handleClose={handleClose} />
        </ClickAwayListener>
      </Menu>
    </div>
  );
};
export default MenuLinks;

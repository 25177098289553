const FormatDateTime = (dataTime) => {
    const date = new Date(dataTime * 1000);
  
    const formattedDate = date.toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    });
  
    return {
      date: formattedDate,
      time: formattedTime
    };
  };
  
  export default FormatDateTime;
  
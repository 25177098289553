import "./UserProfileDetails.scss";
import {
  Box,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { languages } from "../../App";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { FiEdit } from "react-icons/fi";
import ProfileBox from "./ProfileBox";
import ProfileImg from "./ProfileImg";
import ChangePassword from "../ChangePassword/ChangePassword";
import MainCard from "../../_Components/Common/Cards/MainCard";
import {
  PrimaryBgBtn,
  PrimaryOutlineBtn,
} from "../../_Components/Common/Buttons/CustomBtns";

function UserProfileDetails() {
  let curLanguage = useContext(languages);
  const [profileInfo, setProfileInfo] = useState("");
  const [schoolName, setSchoolName] = useState(null);
  const userinfoObject = JSON.parse(localStorage.getItem("userInfo"));
  const userId = userinfoObject[0].id;
  const username = userinfoObject[0].id;
  const [saveImg, setSaveImg] = useState(false);
  const [isEditing, setIsEditing] = useState(true);

  const teacher = userinfoObject[0].department.toLowerCase() === "pedagogy";
  const student = userinfoObject[0].department.toLowerCase() === "student";

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_nodewsPrefix}/getUserProfile?userid=${userId}`
      )
      .then((response) => {
        setProfileInfo(response.data.info);
      });

    const fetchSchoolDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_nodewsPrefix}/getAllSchools`
        );

        const selectedSchoolInfo = response.data.info.find(
          (data) => data.id === Number(userinfoObject[0]?.institution)
        );
        setSchoolName(selectedSchoolInfo);
      } catch (error) {
        console.error("Error fetching school details:", error);
      }
    };

    fetchSchoolDetails();
  }, []);

  const [profileImgInfo, setProfileImgInfo] = useState(
    userinfoObject[0]?.profileimageurl
  );
  const handleUpdateImg = ({ updatedIMG }) => {
    setProfileImgInfo(updatedIMG);
  };

  const handleImgUpload = () => {
    setSaveImg(true);
  };
  const handleEditing = () => {
    setIsEditing(false);
  };
  return (
    <Box className="profileWrapper">
      <MainCard
        className="profilecontent"
        mainPage={true}
        cardTitle="View Profile"
      >
        <Box className="profileBox">
          <Grid container className="profiler">
            <Grid item lg={1} md={1} sm={12} xs={12} className="imgBox">
              <ProfileImg
                isEditing={isEditing}
                profileImgInfo={profileImgInfo}
                userId={userId}
                onImgUpload={handleImgUpload}
                username={username}
                onUpdatedProfileImgUrl={handleUpdateImg}
                handleEditing={handleEditing}
              />
            </Grid>
            <Grid item lg={11} md={11} sm={12} xs={12} className="imgBox">
              <Box className="profileInformation">
                <Typography variant="h4" className="heading">
                  {profileInfo[0]?.firstname} {profileInfo[0]?.lastname}
                </Typography>
                <Typography variant="h5">School: {schoolName?.name}</Typography>
                <Grid container>
                  <Grid item md={4} sm={12} xs={12}>
                    <FormLabel>Role</FormLabel>
                    <Typography>
                      {teacher ? "Teacher" : userinfoObject[0].department}
                    </Typography>
                  </Grid>
                  <Grid item md={4} sm={12} xs={12}>
                    <FormLabel>Last Access</FormLabel>
                    <Typography>{profileInfo[0]?.lastaccess}</Typography>
                  </Grid>
                  <Grid item md={4} sm={12} xs={12}>
                    <FormLabel>Joined on</FormLabel>
                    <Typography>{profileInfo[0]?.timecreated}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <Box p={2}>
            <Typography variant="h6" mb={1}>
              Personal Information
            </Typography>
            <Grid container className="profileInfo" spacing={2}>
              {student && (
                <ProfileBox>
                  <FormLabel>Roll number</FormLabel>
                  <Typography>{profileInfo[0]?.idnumber}</Typography>
                </ProfileBox>
              )}

              <ProfileBox>
                <FormLabel>{curLanguage.username}</FormLabel>
                <Typography>{userinfoObject[0].username}</Typography>
              </ProfileBox>
              <ProfileBox>
                <FormLabel>{curLanguage.Emailaddress}</FormLabel>
                <Typography>{userinfoObject[0].email}</Typography>
              </ProfileBox>

              <ProfileBox>
                <FormLabel>Gender</FormLabel>
                <Typography>{profileInfo[0]?.gender}</Typography>
              </ProfileBox>
              <ProfileBox>
                <FormLabel>Primary Contact</FormLabel>
                <Typography>{profileInfo[0]?.phone1}</Typography>
              </ProfileBox>

              <ProfileBox>
                <FormLabel>Alternate Contact</FormLabel>
                <Typography>{profileInfo[0]?.phone2}</Typography>
              </ProfileBox>
              <ProfileBox>
                <FormLabel>Address</FormLabel>
                <Typography>{profileInfo[0]?.address}</Typography>
              </ProfileBox>
              <ProfileBox>
                <FormLabel>{curLanguage.Citytown}</FormLabel>
                <Typography>{profileInfo[0]?.city}</Typography>
              </ProfileBox>
              <ProfileBox>
                <FormLabel>{curLanguage.state}</FormLabel>
                <Typography>{profileInfo[0]?.state}</Typography>
              </ProfileBox>
              <ProfileBox>
                <FormLabel>{curLanguage.country}</FormLabel>
                <Typography>
                  {profileInfo[0]?.country === "IN" && "India"}
                </Typography>
              </ProfileBox>
            </Grid>
          </Box>
        </Box>
      </MainCard>
    </Box>
  );
}
export default UserProfileDetails;

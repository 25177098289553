import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useState } from "react";
import StudentProgressChart from "./StudentProgressChart";
import Loader from "../../_Components/Utils/loader/Loader";
import { PrimaryBgBtn } from "../../_Components/Common/Buttons/CustomBtns";
import { Scrollbar } from "react-scrollbars-custom";

const Activities = ({ activity, heading }) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(
    new Array(activity.length).fill(false)
  );

  const handleAccordionChange = (index) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  let scoredGroupedActivities = [];
  if (userInfo[0].department.toLowerCase() === "principal") {
    scoredGroupedActivities = activity.reduce((acc, activities) => {
      const { scrom_name, attempt, score_raw, score_max } = activities.activity;
      if (!acc[scrom_name]) {
        acc[scrom_name] = [];
      }

      let status;
      let chapter;
      if (attempt !== undefined) {
        status = attempt
          ? score_raw && score_raw.lesson_status
            ? score_raw.lesson_status === "passed"
              ? "Completed"
              : score_raw.lesson_status === "failed"
              ? "Incomplete"
              : score_raw.lesson_status
            : "Unknown"
          : "Not Attempted";
      } else {
        status =
          attempt !== false
            ? score_raw
              ? score_raw[Object.keys(score_raw)[0]]?.lesson_status
              : ""
            : "";
      }

      // Set chapter name to the section name
      chapter = activities.section_name;

      // Push data into acc
      acc[scrom_name].push({
        chapter,
        status,
      });

      return acc;
    }, {});
  } else {
    scoredGroupedActivities = activity.reduce((acc, activities) => {
      const { scrom_name, lesson_status, attempt } = activities.activity;
      if (!acc[scrom_name]) {
        acc[scrom_name] = [];
      }
      let status;
      if (lesson_status !== undefined) {
        status = lesson_status;
      } else if (attempt !== undefined && attempt === false) {
        status = "Not Attempted";
      } else {
        status = "Unknown";
      }
      acc[scrom_name].push({ chapter: activities.section_name, status });
      return acc;
    }, {});
  }
  const sortedScoredGroupedActivities = {};
  Object.keys(scoredGroupedActivities)
    .sort()
    .forEach((key) => {
      sortedScoredGroupedActivities[key] = scoredGroupedActivities[key];
    });

  const checkStatus = (activities) => {
    const completed = activities.every(
      (activity) => activity.status === "completed"
    );
    return completed ? "completed" : "not-completed";
  };

  return (
    <>
      <Box className="activityHeader">
        <Typography>{heading}</Typography>
        <Box>
          <PrimaryBgBtn onClick={handleOpen} sx={{ ml: 1 }}>
            View Report
          </PrimaryBgBtn>
        </Box>
      </Box>
      <Box className="activityHeader">
        <Typography>Activity</Typography>
        <Typography>Status</Typography>
      </Box>
      {/* MEanwhile it fetch data in scoredGroupedActivities*/}
    
        {Object.entries(sortedScoredGroupedActivities).map(
          ([scrom_name, activities, index]) => (
            <Accordion
              key={scrom_name}
              expanded={expanded[index]}
              onChange={() => handleAccordionChange(index)}
            >
              <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                aria-controls={`panel${index + 1}-content`}
                id={`panel${index + 1}-header`}
              >
                <Typography>{scrom_name}</Typography>
                <Typography className={checkStatus(activities)?.toLowerCase()}>
                  {checkStatus(activities)?.toLowerCase() === "completed" ||
                  checkStatus(activities)?.toLowerCase() === "passed"
                    ? "Completed"
                    : "Not Completed"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container className="insideHeader">
                  <Grid item xs="8">
                    <Typography>Chapter</Typography>
                  </Grid>

                  <Grid item xs="4" textAlign="right">
                    <Typography>Status</Typography>
                  </Grid>
                </Grid>
                {activities.map((activity, index) => (
                  <Grid container key={index}>
                    <Grid item xs={8}>
                      <Typography>{activity.chapter}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        textTransform={"capitalize"}
                        textAlign="right"
                        className={
                          activity.status?.toLowerCase() === "completed" ||
                          activity.status?.toLowerCase() === "passed"
                            ? "completed"
                            : "not-completed"
                        }
                      >
                        {activity.status
                          ? activity.status.toLowerCase() === "passed"
                            ? "Completed"
                            : activity.status.toLowerCase() === "failed"
                            ? "Incomplete"
                            : activity.status
                          : null}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </AccordionDetails>
            </Accordion>
          )
        )}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"lg"}>
        <DialogTitle>{heading} Report</DialogTitle>
        <DialogContent>
          <StudentProgressChart activity={scoredGroupedActivities} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Activities;
